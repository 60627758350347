.title-details.sport {
  .poster {
    position: relative;
    cursor: pointer;
    .play {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 54px;
      height: 54px;
      -webkit-backdrop-filter: blur(2.7px);
      backdrop-filter: blur(2.7px);
      background-color: $green;
      border-radius: 50%;
      cursor: pointer;
      z-index: 1;
      img {
        position: absolute;
        top: 50%;
        left: 53%;
        transform: translate(-50%, -50%);
        width: 22px;
        height: 18px;
      }
    }
  }
  .controls {
    .watch-buy {
      justify-content: flex-start;
    }
    .button-custom {
      width: auto;
    }
  }
}
