/*----------------------------------------------------------------------------------------------------------------------
  Colors
----------------------------------------------------------------------------------------------------------------------*/
$light-green: rgb(184, 233, 134);
$very-light-green: rgba(184, 233, 134, 0.4);

$green: #75b952;
$green-opacity-015: rgba(117, 185, 82, 0.16);

$green_2: #b8e986;

$gray: #434346;
$gray-opacity-1: #c2c2c2;
$gray-opacity-08: rgba(194, 194, 194, 0.8);
$gray-opacity-07: rgba(194, 194, 194, 0.7);
$gray-opacity-06: rgba(194, 194, 194, 0.8);
$gray-opacity-05: rgba(194, 194, 194, 0.5);
$gray-opacity-04: rgba(194, 194, 194, 0.4);
$gray-opacity-01: rgba(194, 194, 194, 0.1);

$black-opacity-25: rgba(0, 0, 0, 0.25);

$gray2-opacity-1: #b5b5b5;
$gray2-opacity-05: rgba(181, 181, 181, 0.5);
$gray2-opacity-07: rgba(181, 181, 181, 0.7);

$darker-grey: rgba(235, 235, 235, 0.1);
$white: #ffffff;
$white-two: rgba(235, 235, 235, 0.7);
$white-opacity-01: rgba(235, 235, 235, 0.1);
$white-opacity-03: rgba(235, 235, 235, 0.3);
$white-opacity-04: rgba(235, 235, 235, 0.4);
$white-opacity-05: rgba(235, 235, 235, 0.5);
$white-opacity-06: rgba(235, 235, 235, 0.6);
$white-opacity-07: rgba(235, 235, 235, 0.7);
$white-opacity-08: rgba(255, 255, 255, 0.8);
$white-opacity-09: rgba(255, 255, 255, 0.9);
$overlay-bg: rgba(46, 46, 50, 0.85);
$blue: #4b90e2;

/*----------------------------------------------------------------------------------------------------------------------
  Paths
----------------------------------------------------------------------------------------------------------------------*/

$path_fonts: '/static/fonts';

/*----------------------------------------------------------------------------------------------------------------------
  Fonts
----------------------------------------------------------------------------------------------------------------------*/
$font-Regular: 'Mulish';
$font-Bold: 'Mulish';

$fonts: ($font-Regular, $font-Bold);

@each $font in $fonts {
  @font-face {
    font-family: $font;
    src: url('#{$path_fonts}/#{$font}/#{$font}.eot');
    src: url('#{$path_fonts}/#{$font}/#{$font}.eot?#iefix')
        format('embedded-opentype'),
      url('#{$path_fonts}/#{$font}/#{$font}.woff') format('woff'),
      url('#{$path_fonts}/#{$font}/#{$font}.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
}

/*----------------------------------------------------------------------------------------------------------------------
  Global base styles
----------------------------------------------------------------------------------------------------------------------*/
// App Global Font Family
$font-family-base: $font-Regular, sans-serif;
// App Global Font Size
$font-size-base: 14px;

$transition: all 0.3s ease;

$scroll-width: 10px;

$overlay-z-index: 1000;
