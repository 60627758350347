::-webkit-scrollbar {
  -webkit-appearance: unset;
  width: $scroll-width;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  // -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
.overlay {
  .souces-overlay {
    margin-top: 30px;
    height: 149px;
    overflow-y: auto;

    // &::-webkit-scrollbar {
    //   width: $scroll-width;
    //   scrollbar-width: thin;
    // }

    // &::-webkit-scrollbar-thumb {
    //   background-color: rgba(235, 235, 235, 0.1);
    //   outline: 1px solid rgba(235, 235, 235, 0.1);
    // }
  }
  .sources {
    .source-item {
      flex-basis: 48% !important;
      margin: 5px !important;
      max-width: 488px !important;
      .status-icon {
        top: 30%;
      }
    }
  }
}

.promo-subscriptions {
  &__title {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    text-align: center;
    color: #b2b2b2;
  }
  &__subtitle {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    text-align: center;
    color: #b2b2b2;

    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    /* or 137% */

    text-align: center;

    mix-blend-mode: normal;
    opacity: 0.8;
  }

  &__header {
    text-align: center;
    margin: 0px 0 36px 0;
    .desc {
      width: 750px;
      max-width: 100%;
      margin: 20px auto 0;
    }
  }
  &__content {
    width: 1170px;
    max-width: 100%;
    margin: 0 auto;
    .promo-subscriptions__lists {
      &-list {
        margin-bottom: 50px;
        .list__header {
          display: flex;
          align-items: flex-end;
          justify-content: space-between;
          .button {
            margin: 0;
          }
        }
        .sources {
          display: flex;
          flex-wrap: wrap;
          .source-item {
            flex-basis: 49%;
            margin: 5px;
            &.active {
              .status-icon {
                background: url('../../../assets/images/ico/lists/seen-green.svg')
                  center center no-repeat;
              }
            }
            .status-icon {
              background: url('../../../assets/images/ico/plus.svg') center
                center no-repeat;
            }
          }
        }
      }
    }
  }
}
