.green {
  color: $green;
  &.light {
    color: $light-green;
  }
}
.gray {
  color: $white-two;
}
.dark-gray {
  color: $darker-grey;
}

// Gray 1
.gray-opacity-01 {
  color: $gray-opacity-01;
}
.gray-opacity-07 {
  color: $gray-opacity-07;
}
.gray-opacity-05 {
  color: $gray-opacity-05 !important;
}
.gray-opacity-04 {
  color: $gray-opacity-04;
}
.gray-opacity-08 {
  color: $gray-opacity-08;
}
.gray-opacity-1 {
  color: $gray-opacity-1;
}

// Gray 2
.gray2-opacity-1 {
  color: $gray2-opacity-1;
}
.gray2-opacity-05 {
  color: $gray2-opacity-05;
}
.gray2-opacity-07 {
  color: $gray2-opacity-07;
}
// White
.white {
  color: $white;
}
.white-opacity-03 {
  color: $white-opacity-03;
}
.white-opacity-04 {
  color: $white-opacity-04;
}
.white-opacity-05 {
  color: $white-opacity-05;
}
.white-opacity-06 {
  color: $white-opacity-06;
}
.white-opacity-07 {
  color: $white-opacity-07;
}
.white-opacity-08 {
  color: $white-opacity-08;
}
.white-opacity-09 {
  color: $white-opacity-09;
}
.blue {
  color: $blue;
}
