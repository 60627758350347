.advertising {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  .title {
    text-align: center;
  }
  &-items {
    display: flex;
    margin-top: 15px;
    &__item {
      margin-right: 40px;
      &:last-child {
        margin-right: 0;
      }
      .image {
        border-radius: 4px;
        width: 64px;
        height: 64px;
        flex-shrink: 0;
        background: #000;
        overflow: hidden;
        img {
          width: 100%;
        }
      }
    }
  }
}
