&.episode {
  text-align: initial;
  &.empty {
    width: 320px;
    @media all and (max-width: 720px) {
      width: 140px;
    }
  }
  .inner {
    width: 360px;
    .image {
      position: relative;
      height: 200px !important;
      &:after {
        visibility: hidden;
        opacity: 0;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: all 0.15s ease;
      }
    }
  }
  .item-details {
    .title {
      display: flex;
      justify-content: space-between;
    }
    .desc {
      height: 32px;
      margin-top: 0;
    }
  }
  .more {
    width: 100% !important;
    height: 100% !important;
  }
}
