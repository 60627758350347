.login {
  text-align: center;
  &-text {
    margin-bottom: 40px;
    .title {
      margin-bottom: 20px;
      h2 {
        line-height: 44px;
      }
    }
  }
  .buttons {
    .google {
      .button {
        color: #565555 !important;
      }
    }
    .button {
      width: 300px;
    }
    .text-after {
      color: $gray-opacity-06;
      font-size: 13px;
    }
  }
}
