.grid-default {
  padding: 0 0 0 40px;
  outline: none;
  flex: 1 1 auto;
  &::-webkit-scrollbar {
    display: none;
  }
  .grid-item__list {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    flex-flow: row wrap;
    padding-top: 15px;
    overflow: hidden;
  }
}

.grids-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
