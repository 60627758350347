.promo-grid-carousel__wrapper {
  overflow: hidden;
  margin-bottom: 16px;
  .grid-carousel {
    outline: none;
    padding-top: 1px;
    margin-bottom: -16px;
    padding-left: 1px;
    // &::-webkit-scrollbar {
    //   display: none;
    // }
  }
}
