.inner {
  position: relative;
  width: 560px;
  height: 380px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  .image-wrapper {
    width: 100%;
    height: 380px;
    position: absolute;
    z-index: -1;
    display: flex;
    align-items: flex-start;
    flex-direction: row-reverse;
    box-shadow: 0 15px 20px 0 rgba(0, 0, 0, 0.25);
    background: #17272c;
    .image {
      height: 100%;
      margin-bottom: 0;
    }
    img {
      width: 100%;
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    flex: 1 1 0;
    padding: 39px 35px 0 35px;
    height: 100%;
    .logo {
      width: 32px;
      margin-bottom: 10px;
    }
    .about {
      max-width: 70%;
      text-align: left;
      .source {
        width: 48px;
        height: 24px;
        img {
          width: 100%;
        }
      }
      .title {
        color: $white;
        margin: 10px 0;
      }
      .description {
        white-space: normal;
        text-align: left;
        margin-top: 10px;
      }
    }
  }
  .slider-wrapp {
    width: 100%;
    .slider {
      overflow: hidden;
      .slider-tray-wrap {
        height: 100%;
        .slider-tray {
          display: flex;
          flex-direction: row;
          margin: 0;
          height: 100%;
          transition: all 2s linear;
          li {
            margin: 5px;
            list-style-type: none;
            &.horizontal {
              width: 180px !important;
              height: 100px !important;
            }
            &.vertical {
              width: 100px !important;
              height: 150px !important;
            }
            img {
              width: 100%;
              height: 100%;
              border-radius: 8px;
            }
          }
        }
      }
    }

    img {
      width: 100px;
    }
  }
}
