.search {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  .filters-wrapper {
    margin-top: 30px;
  }
  .value {
    display: inline-block;
    margin-left: 20px;
  }
}
