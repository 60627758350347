.custom-select {
  position: relative;
  margin-top: 10px;
  .selected {
    position: relative;
    display: flex;
    align-items: center;
    color: $white-opacity-08;
    width: 110px;
    height: 34px;
    margin: 0 auto;
    border-radius: 20px;
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
    background-color: rgba(65, 65, 68, 0.9);
    cursor: pointer;
    span {
      padding-left: 18px;
    }
    &.active {
      background-color: rgba(235, 235, 235, 0.3);
      .arrow {
        transform: rotateX(-180deg) translateY(-50%);
        top: 29%;
      }
    }
    .arrow {
      position: absolute;
      right: 13px;
      top: 53%;
      transform: rotateX(0) translateY(-50%);
      width: 13px;
      height: 8px;
      background: url('../images/ico/arrow-down.svg') 0 0 no-repeat;
      transition: $transition;
    }
  }
  .select-list {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 45px;
    left: 50%;
    transform: translateX(-50%);
    max-width: 110px;
    width: 110px;
    padding: 10px 0;
    border-radius: 10px;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    box-shadow: 0 20px 20px 0 rgba(0, 0, 0, 0.2);
    background-color: rgba(65, 65, 68, 0.9);
    transition: $transition;
    &.active {
      opacity: 1;
      visibility: visible;
    }
    &__item {
      display: flex;
      align-items: center;
      padding-left: 18px;
      width: 100%;
      height: 30px;
      color: #b2b1b2;
      cursor: pointer;
      &.active,
      &:hover {
        color: $white;
        -webkit-backdrop-filter: blur(6.7px);
        backdrop-filter: blur(6.7px);
        background-color: rgba(235, 235, 235, 0.1);
      }
    }
  }
}
