.sources-list-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-right: 30px;
  .sources-title {
    color: $gray;
    margin: 0;
    text-align: right;
  }
  .sources-list {
    display: flex;
    margin: 0;
    .source-image {
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
