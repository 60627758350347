.subscriptions {
  &__header {
    text-align: center;
    margin: 21px 0 70px;
    .desc {
      width: 750px;
      max-width: 100%;
      margin: 20px auto 0;
    }
  }
  &__content {
    width: 1170px;
    max-width: 100%;
    margin: 0 auto;
    .subscriptions__lists {
      &-list {
        margin-bottom: 50px;
        .list__header {
          display: flex;
          align-items: flex-end;
          justify-content: space-between;
          .button {
            margin: 0;
          }
        }
        .sources {
          margin-top: 30px;
          display: flex;
          flex-wrap: wrap;
          .source-item {
            flex-basis: 31.33%;
            margin: 0 3% 10px 0;
            &:nth-child(3n) {
              margin-right: 0;
            }
            &.active {
              .status-icon {
                background: url('../../assets/images/ico/lists/seen-green.svg')
                  center center no-repeat;
              }
            }
            .status-icon {
              background: url('../../assets/images/ico/plus.svg') center center
                no-repeat;
            }
          }
        }
      }
    }
  }
}
