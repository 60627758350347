.global-search-wrapper {
  position: relative;
  display: flex;
  max-width: 360px;
  width: 100%;
  min-width: 120px;
  .search-input-wrapper {
    border-bottom: none;
    background-color: $darker-grey;
    border: 1px solid #2e2e32;

    border-radius: 22px;
    width: 100%;
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
    &.Mui-focused {
      border: 1px solid #75b952;
    }

    .global-search {
      height: 40px;
      padding: 0 16px;
      margin-left: 25px;
      border-bottom: 0;
      font-size: 14px;
      font-family: $font-Bold;
      // color: $white-opacity-04;
      color: $white;
      &::placeholder {
        color: $white-opacity-08 !important;
      }
    }
    .search-icon-wrapper {
      position: absolute;
      left: 6px;
      top: 8px;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    .search-icon-clear-wrapper {
      margin: 0 15px -5px;
      width: 10px;
      height: 10px;
      cursor: pointer;
    }
    .search-icon {
      width: 18px;
      height: 18px;
      margin-top: -5px;
    }
    .search-icon-clear {
      width: 100%;
      margin-top: -5px;
    }
  }

  @import 'suggestions';
}
