.carousel-actions {
  display: flex;
  .button-title__action {
    margin-right: 8px;
    height: 30px;
    width: 30px;
    -webkit-tap-highlight-color: transparent;
    &.active {
      background-color: $white-opacity-03;
      -webkit-tap-highlight-color: transparent;
    }
  }
  .edit {
    display: flex;
    align-items: center;
    .edit-confirm {
      display: flex;
      visibility: hidden;
      opacity: 0;
      width: 100%;
      align-items: center;
      position: absolute;
      left: 34px;
      top: -1px;
      background: #2e2e32;
      z-index: 1;
      transform: $transition;
      &.active {
        visibility: visible;
        opacity: 1;
      }
      &.error {
        .edit-list__filed--wrapper {
          background-color: rgba(251, 36, 77, 0.5);
        }
      }
      .edit-list__filed--wrapper {
        background-color: rgba(235, 235, 235, 0.1);
        border-radius: 22px;
        margin-right: 20px;
        padding: 0 20px;
        input {
          color: $white;
          font-size: 22px;
          font-family: $font-Bold;
        }
      }
    }
  }
  .remove {
    position: relative;
    display: flex;
    align-items: center;
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: -3px;
      width: 184px;
      height: 36px;
      border-radius: 100px;
      transition: $transition;
    }
    .remove-confirm {
      visibility: hidden;
      opacity: 0;
      position: absolute;
      right: -200px;
      top: 50%;
      transform: translateY(-50%);
      transition: all 0.3s cubic-bezier(0.8, -0.47, 0.25, 0.23);
      .button {
        margin: 0 !important;
        width: 128px;
        height: 30px;
        padding: 0 !important;
        justify-content: center !important;
        .info {
          display: none !important;
        }
      }
    }
    &.active {
      &:before {
        background-image: linear-gradient(
          to right,
          rgba(65, 65, 68, 0.3),
          rgba(65, 65, 68, 0.3)
        );
      }
      .remove-confirm {
        visibility: visible;
        opacity: 1;
        right: -135px;
      }
    }
  }
}
