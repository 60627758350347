&.promo {
  text-align: initial;
  &.empty {
    width: 320px;
    @media all and (max-width: 720px) {
      width: 140px;
    }
  }
  .item-details {
    position: absolute;
    bottom: 16px;
    left: 24px;
  }
  .inner {
    width: 360px;
    .image {
      height: 200px !important;
    }
    .overview {
      margin-bottom: 4px;
    }
  }
}
