.button {
  font-family: $font-Bold !important;
  font-size: 16px !important;
  color: $white-opacity-08;
  text-transform: initial !important;
  padding: 0 24px !important;
  height: 40px;
  outline: none;
  border: 1px solid transparent;
  cursor: pointer;
  margin: 11px;
  position: relative;
  transition: $transition;
  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  &.active {
    -webkit-backdrop-filter: blur(9px) !important;
    backdrop-filter: blur(9px) !important;
    background-color: #75b952 !important;
  }
  &-arrow {
    box-sizing: border-box;
    appearance: none;
    background-color: transparent;
    border-radius: 50%;
    border: none;
    padding: 0;
    cursor: pointer;
    margin: 0 5px;
    outline: none;
    img {
      width: 28px;
      height: 28px;
    }
  }
  &.button-custom {
    > img {
      margin-right: 10px;
    }
  }
  &-title__action {
    position: relative;
    width: 28px;
    height: 28px;
    padding: 0;
    border-radius: 50%;
    border: none;
    background-color: rgba(235, 235, 235, 0.1);
    cursor: pointer;
    margin: 0 5px;
    outline: none;
    transition: $transition;
    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    &.border {
      border: solid 1px rgba(235, 235, 235, 0.1);
    }
    &.transparent {
      background: transparent;
      width: 22px !important;
      height: 22px !important;
    }
    &:hover {
      border-color: transparent;
      background-color: rgba(235, 235, 235, 0.1);
      .tooltip {
        visibility: visible;
        opacity: 1;
      }
    }
    &.active {
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
      background: #75b952;
    }
    img {
      position: absolute !important;
      left: 50%;
      transform: translate(-50%, -50%) !important;
      height: 50% !important;
      margin: 0;
    }
    .tooltip {
      visibility: hidden;
      opacity: 0;
      position: absolute;
      bottom: -20px;
      left: 50%;
      transform: translateX(-50%);
      transition: $transition;
    }
  }
  &.view-all {
    height: 28px;
    &:hover {
      background: $white-opacity-04 !important;
    }
  }
  &.filter-reset {
    .icon {
      width: 12px;
      height: 12px;
      left: 20px;
      top: 53%;
    }
  }
  &.transparent {
    background: none !important;
  }
  &.block {
    width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
  }
  &.circle {
    border-radius: 50%;
    &.medium {
      width: 44px;
      height: 44px;
      padding: 13px !important;
    }
    .icon {
      position: relative;
      width: 100%;
      height: 100%;
      left: inherit;
      top: inherit;
    }
  }
  &.with-icon {
    padding-left: 43px !important;
  }
  &.large {
    width: 300px;
    height: 50px;
  }
  &.medium {
    width: 250px;
    height: 50px;
  }
  &.small {
    width: auto;
    margin: 0 15px;
    min-width: 110px;
  }
  &.rounded {
    border-radius: 25px;
  }
  &.rectangular {
    border-radius: 8px;
  }
  &.bordered {
    border: solid 2px rgba(226, 229, 233, 0.1);
  }
  &.link {
    color: $white-opacity-08;
    display: flex;
    height: auto;
    width: auto;
    align-self: center;
    align-items: center;
    background-color: transparent !important;
    padding: 0 !important;
    min-width: max-content;
    .icon {
      position: initial;
      margin-right: 10px;
      transform: none;
    }
    &.no-underline {
      text-decoration-line: none;
    }
  }
  &.bg {
    &-gray {
      background-color: $darker-grey;
    }
    &-blue {
      background-color: $blue;
    }
    &-green {
      background-color: $green;
    }
    &-light-green {
      background-color: $light-green;
    }
    &-blured {
      -webkit-backdrop-filter: blur(9px);
      backdrop-filter: blur(9px);
      background-color: rgba(255, 255, 255, 0.12);
    }
    &-dark-gray {
      background-color: $darker-grey;
    }
    &-transparent {
      background-color: transparent !important;
    }
  }
  &.login {
    min-width: 130px;
    color: $white;
    margin-left: 23px;
  }
  &.blured {
    -webkit-backdrop-filter: blur(9px);
    backdrop-filter: blur(9px);
    background-color: rgba(255, 255, 255, 0.12);
  }
  &.green {
    background-color: $green;
  }
  .icon {
    position: absolute;
    left: 28px;
    top: 50%;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
  }
  &.advanced-button {
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    align-items: center;
    .label {
      display: flex;
      flex-direction: row;
      align-items: center;
      .text {
        margin-left: 5px;
      }
      .icon {
        position: relative;
        top: 0;
        left: 0;
        color: $white-opacity-06;
        margin-left: -6px;
      }
    }
    .info {
      display: flex;
      flex-direction: row;
    }
    .text {
      font-size: 16px;
      font-family: $font-Regular;
      letter-spacing: normal;
      margin: 0;
      line-height: 0;
      &.secondary {
        color: $white-opacity-06;
      }
      &.value {
        margin-left: 7px;
      }
    }
  }
}
