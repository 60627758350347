.more {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 0;
  width: max-content;
  height: max-content;
  transition: all 0.1s linear;
  &.bottom {
    bottom: 0;
    top: inherit;
    width: 100%;
  }
  .play {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 54px;
    height: 54px;
    -webkit-backdrop-filter: blur(2.7px);
    backdrop-filter: blur(2.7px);
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    cursor: pointer;
    z-index: 1;
    img {
      position: absolute;
      top: 50%;
      left: 53%;
      transform: translate(-50%, -50%);
      width: 22px;
      height: 18px;
    }
  }
  .actions {
    position: absolute;
    display: flex;
    width: max-content;
    height: max-content;
    z-index: 1;
    &.bottom {
      bottom: 25px;
      left: 50%;
      transform: translateX(-50%);
    }
    &.right {
      flex-direction: column;
      right: 7px;
      top: 7px;
      @media screen and (max-width: 480px) {
        top: 0;
      }
      .button-title__action {
        margin-bottom: 8px;
        -webkit-tap-highlight-color: transparent;
        @media screen and (max-width: 480px) {
          margin-bottom: 2px;
        }
        &:last-child {
          margin-bottom: 0;
        }
        .tooltip {
          display: none;
        }
      }
    }
    .button-title__action {
      width: 30px;
      height: 30px;
      -webkit-tap-highlight-color: transparent;
    }
  }
}
