html,
body {
  font-family: $font-family-base;
  font-size: $font-size-base;
  overflow-x: hidden !important;
  overflow-y: auto;
  -webkit-overflow-scrolling: auto;
  background: #2e2e32;
  color: #fff;
  margin: 0;
  height: 100%;
  // &::-webkit-scrollbar {
  //   display: none;
  // }

  /* Customize website's scrollbar like Mac OS
  Not supports in Firefox and IE */

  /* total width */
  // ::-webkit-scrollbar {
  //   background-color: transparent;
  //   width: 0;
  //   height: 0;
  // }

  // ::-webkit-scrollbar-corner {
  //   background: rgba(0, 0, 0, 0) !important;
  // }

  // /* background of the scrollbar except button or resizer */
  // ::-webkit-scrollbar-track {
  //   background-color: transparent !important;
  // }

  // /* set button(top and bottom of the scrollbar) */
  // ::-webkit-scrollbar-button {
  //   display: none !important;
  // }

  #root {
    height: 100%;
  }

  .app-container {
    height: 100%;
    -webkit-overflow-scrolling: touch;
    overflow-x: hidden !important;
    overflow-y: hidden;
    background: #2e2e32;
    color: #fff;
  }
  .container {
    padding: 0 40px 0;
    @media only screen and (min-width: 320px) and (max-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
      padding: 0 20px 0;
    }
  }
  .page {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    height: calc(100vh - 82px);
    overflow-x: hidden;
  }
  .content {
    flex-grow: 2;
  }
  .logo {
    width: 116px;

    @media only screen and (min-width: 320px) and (max-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
      width: 90px;
    }
  }
}

a {
  text-decoration: none;
}

ul,
li {
  padding: 0;
  margin: 0;
  list-style: none;
}

div,
ul,
li {
  box-sizing: border-box;
}

iframe {
  div {
    z-index: 1000000;
  }
}

pre {
  display: block;
  font-family: $font-Regular;
  white-space: pre-line;
  margin: 0;
}
