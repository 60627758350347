.sources-wrapper {
  width: 400px;
  .MuiTabs-root {
    border-bottom: 1px solid rgba(216, 216, 216, 0.1);
    margin-bottom: 27px;
    .MuiButtonBase-root {
      padding-top: 0;
      color: $white-opacity-08;
      text-transform: initial;
      &.Mui-selected {
        color: $light-green;
      }
    }
  }
}
.sources {
  .empty {
    margin: 0 auto;
    text-align: center;
  }
  .source-item {
    max-width: 400px !important;
    flex-basis: 100% !important;
    margin: 0 0 10px !important;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
