.filter-list {
  &.columns-2 {
    width: 410px;
  }
  &.columns-3 {
    width: 700px;
  }
  &__header {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    max-height: 380px;
    .scrollbar-custom {
      & > div {
        display: flex;
      }
    }
    .items {
      display: flex;
      flex-flow: column wrap;
    }
  }
  &-item {
    display: flex;
    align-items: center;
    padding-left: 10px;
    width: 190px;
    height: 30px;
    margin-bottom: 8px;
    border-radius: 6px;
    margin-right: 30px;
    cursor: pointer;
    transition: $transition;
    &:nth-child(3n) {
      margin-right: 0;
    }
    &.selected {
      background-color: $green;
      h5 {
        color: $white !important;
      }
    }
    &:hover {
      background-color: #929293;
      h5 {
        color: $white !important;
      }
    }
  }
}
