.breadcrumbs {
  .button {
    margin: 0;
    background: $gray;
    opacity: 1;
    .icon {
      width: 8px;
    }
  }
}
