.grid-page__wrapper {
  margin-bottom: 16px;
  margin-top: 40px;
  overflow: auto;
}
.grid-carousel__wrapper {
  overflow: hidden;
  margin-bottom: 16px;
  .grid-carousel {
    outline: none;
    padding-top: 15px;
    margin-bottom: -16px;
    padding-left: 40px;
    @media all and (max-width: 720px) {
      padding-left: 10px;
    }
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
