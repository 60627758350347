&.season {
  text-align: initial;
  .inner {
    text-align: center;
    .image {
      position: relative;
      .info {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-direction: column;
        padding-bottom: 12px;
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 100%;
        height: 50%;
        transform: translateX(-50%);
        z-index: 1;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, #000);
        .title {
          text-transform: uppercase;
          text-align: center;
        }
      }
    }
  }
  &.empty {
    width: 200px;
    @media all and (max-width: 720px) {
      width: 140px;
    }
  }
}
