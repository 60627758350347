.share {
  position: relative;
  display: block;
  &-socials {
    position: absolute;
    padding: 13px 19px 20px;
    visibility: hidden;
    opacity: 0;
    right: 0;
    top: -220px;
    z-index: 100;
    border-radius: 20px;
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    box-shadow: 0 20px 20px 0 rgba(0, 0, 0, 0.2);
    background-color: rgba(65, 65, 68, 0.9);
    transition: $transition;
    &.bottom {
      right: 0;
      top: 66px;
      @media only screen and (min-width: 320px) and (max-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
        right: auto;
      }
      .cover {
        bottom: 176px;
        transform: rotate(-136deg);
      }
    }
    &.top {
      @media only screen and (min-width: 320px) and (max-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
        right: auto;
      }
    }

    &.active {
      visibility: visible;
      opacity: 1;
    }
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .close {
        width: 18px;
        height: 18px;
        background: url('../images/ico/close.svg') 0 0 no-repeat;
        opacity: 0.5;
        cursor: pointer;
      }
    }
    &__list {
      margin: 20px 0 15px;
      display: flex;
      align-items: center;
      &-item {
        margin-right: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        &:last-child {
          margin-right: 0;
        }
        .react-share__ShareButton {
          &:focus {
            outline: none;
          }
        }
      }
    }
    .link {
      position: relative;
      width: 100%;
      height: 40px;
      padding: 0 10px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      -webkit-backdrop-filter: blur(6.7px);
      backdrop-filter: blur(6.7px);
      background-color: rgba(235, 235, 235, 0.1);
      .copied-status {
        position: absolute;
        bottom: -15px;
        visibility: hidden;
        opacity: 0;
        transition: $transition;
        &.active {
          visibility: visible;
          opacity: 1;
        }
      }
      input {
        color: $white-opacity-08;
        font-size: 14px;
        font-family: $font-Bold;
        background: none;
        border: none;
        width: 100%;
        padding-right: 10px;
        &::selection {
          background: transparent;
        }
        &:focus {
          outline: none;
        }
      }
      .button {
        margin: 0;
        width: auto;
        backdrop-filter: none;
      }
    }
    .cover {
      position: absolute;
      right: 40px;
      bottom: -9px;
      height: 25px;
      width: 25px;
      overflow: hidden;
      transform: rotate(45deg);
      border-bottom-right-radius: 5px;
      .arrow {
        position: absolute;
        width: 20px;
        height: 30px;
        left: 11px;
        top: 7px;
        background-color: rgba(65, 65, 68, 1);
        transform: rotate(45deg);
      }
    }
  }
}
