.title-details {
  .banner-wrapper {
    height: 700px;
    box-shadow: 0 15px 20px 0 rgba(0, 0, 0, 0.25);
    border-radius: 14px;
    margin: 15px 0;
    overflow: hidden;
    position: relative;
    display: flex;
    background-color: #142630;
    .banner {
      height: 100%;
      position: absolute;
      pointer-events: none;
      left: 235px;
      right: 0;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: right top;
      .gradient {
        display: block;
        position: absolute;
        height: 100%;
        width: 30%;
        z-index: 0;
        top: 0;
        left: 0;
      }
      img {
        width: calc(100% - 235px);
        float: right;
        position: absolute;
      }
    }
  }
  .background-wrapper {
    padding-top: 80px;
    height: 720px;
    position: relative;
    top: -84px;
    left: 0;
    right: 0;
    background-image: linear-gradient(
      to bottom,
      rgba(46, 46, 50, 0.7) 100%,
      #2e2e32 25%
    );
    display: flex;
    flex-direction: row;
    justify-content: center;
    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      background-image: linear-gradient(
        to bottom,
        rgba(46, 46, 50, 0.7) 100%,
        #2e2e32 25%
      );
    }
    &__details {
      position: relative;
      display: flex;
      flex-direction: column;
      max-width: 750px;
      width: 100%;
      min-height: 506px;
      margin-left: 28px;
      margin-top: 105px;
      .actions {
        margin-bottom: 30px;
        margin-left: -8px;
      }
      .bottom {
        margin-top: 22px;
      }
      .services-wrapper {
        margin-top: 78px;
      }
    }
  }

  .details {
    margin: 30px 0;
    z-index: 1;
    .top {
      border-bottom: 1px solid $gray-opacity-01;
      display: flex;
      flex-direction: row;
      align-items: baseline;
      justify-content: space-between;
      margin-bottom: 25px;
    }
    .bottom {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  .poster-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 100px 0;
    align-items: center;
    .poster {
      position: relative;
      border-radius: 14px;
      box-shadow: 0 15px 20px 0 rgba(0, 0, 0, 0.25);
      overflow: hidden;
      &.empty {
        width: 70px;
        height: 70px;
      }
      &:not(.horizontal) {
        height: 370px;
        width: 250px;
      }
      &.horizontal {
        height: 280px;
        width: 500px;
      }
      .owner {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 5px;
        width: 48px;
        height: 24px;
        z-index: 1;
        img {
          width: 100%;
        }
      }
      img {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
      }
    }
    .controls {
      width: 100%;
      .watch-buy {
        display: flex;
        margin: 22px 0 12px;
        justify-content: space-between;
      }
      .button-custom {
        width: 48%;
        height: 40px;
        margin: 8px 0;
        justify-content: center;
        .info {
          display: none;
        }
      }
      .button-link {
        color: $white-opacity-06;
        margin: 15px auto 0;
        .text {
          color: $white-opacity-06;
          font-family: $font-Bold;
        }
        .icon {
          width: 22px;
        }
      }
    }
  }

  .title-wrapper {
    max-width: 50%;
    .title {
      color: $white-opacity-07;
    }
    .info {
      display: flex;
      flex-direction: row;
      align-items: center;
      .rating {
        display: flex;
        flex-direction: row;
        .imdb,
        .tomatoes {
          display: flex;
          align-items: center;
          margin-right: 20px;
          img {
            height: 16px;
            margin-right: 6px;
          }
          span {
            font-size: 16px;
            color: white;
          }
        }
        .imdb {
          img {
            height: 14px;
          }
        }
      }
      .main {
        margin-left: 15px;
      }
      .rate {
        margin-left: 40px;
      }
      .text {
        font-size: 13px;
        color: $gray-opacity-05;
        margin: 6px 0px;
      }
    }
  }

  .actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    .button-custom {
      background-color: $white-opacity-01;
      margin: 0px 0px 0px 10px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: auto;
      white-space: nowrap;
      padding: 0px 20px !important;
      min-width: 100px;
      font-family: $font-Regular !important;
      -webkit-backdrop-filter: blur(8px);
      backdrop-filter: blur(8px);
      height: 40px;
      img {
        margin-right: 10px;
      }
    }
  }

  .details {
    .sources-wrapper {
      width: 50%;
    }
    .summary {
      padding-right: 20px;
      p {
        color: $gray-opacity-05;
        font-family: $font-Regular;
        line-height: 1.38;
        font-size: 16px;
        margin: 0px 0px 10px;
        height: 44px;
        overflow: hidden;
        &.open {
          height: auto;
        }
      }
      .more {
        margin: 0px;
        font-family: $font-Bold;
      }
      .arrow-icon {
        transition: 0.4s;
        margin-left: 8px;
        color: #929293;
        &.up {
          transform: rotate(180deg);
        }
      }
    }
  }
}
