&.sport_category {
  .inner {
    width: 160px !important;
  }
  .image {
    height: 160px !important;
    background-size: cover !important;
    .icon {
      box-shadow: 0 0 25px 5px rgba(184, 233, 134, 0.4);
      object-fit: cover;
      background-color: rgba(184, 233, 134, 0.23);
      border-radius: 50%;
    }
    .title {
      width: 100%;
      position: absolute;
      bottom: 20px;
      left: 50%;
      transform: translateX(-50%);
      margin-bottom: 0;
    }
  }
}
