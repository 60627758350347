.grid {
  &-page {
    padding: 20px 1px 0 38px !important;
  }
}
.grid-navigation {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 0 0 10px;
  height: 35px;
  @media only screen and (min-width: 320px) and (max-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    flex-wrap: wrap;
    height: 70px;
  }
  @import 'grid.carousel.actions';
  .title {
    font-family: $font-Bold;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: -5px;
    .icon {
      position: relative;
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      &:not(.profile-list-icon) {
        &::after {
          content: '';
          width: 50px;
          height: 50px;
          position: absolute;
          top: 50%;
          left: -50%;
          transform: translate(50%, -50%);
          opacity: 0.05;
          border-radius: 50%;
        }
      }
      &.profile-list-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 34px;
        height: 34px;
        border-radius: 50%;
        -webkit-backdrop-filter: blur(9px);
        backdrop-filter: blur(9px);
        background-color: rgba(255, 255, 255, 0.12);
        margin-right: 20px;
      }
      img {
        display: block;
      }
    }
    .text {
      font-size: 22px;
      color: $white-two;
      margin: 0 0 0 5px;
      @media only screen and (min-width: 320px) and (max-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
        width: 280px;
      }
    }
    .count {
      margin: 0 10px;
    }
  }
  .controls {
    display: flex;
    align-items: center;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    @media only screen and (min-width: 320px) and (max-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
      position: relative;
      top: 20%;
    }

    .link {
      color: $white-opacity-04;
      font-size: 16px;
      text-decoration: none;
      margin-right: 38px;
      transition: $transition;
      &:hover {
        color: $light-green;
      }
    }
    .button-title__action {
      -webkit-tap-highlight-color: transparent;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
