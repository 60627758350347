.footer {
  background: rgba(26, 26, 26, 0.6);
  padding: 35px 0;
  @media only screen and (min-width: 320px) and (max-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    display: none;
  }
  .container {
    display: flex;
    justify-content: space-between;
    .footer__copyright {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 30%;

      .logo {
        margin-bottom: 4px;
      }
      .app__stores {
        margin-top: 17px;
        height: 40px;
        display: flex;
        align-items: center;
        a {
          display: block;
          height: 100%;
          &.apple__store {
            width: 118px;
            height: 38px;
            margin-right: 10px;
          }
          &.google__store {
            width: 135px;
            height: 40px;
          }
          img {
            width: 100%;
          }
        }
      }
    }
    .footer__info {
      display: flex;
      width: 70%;
      .footer__sitemap {
        width: 20%;
        max-width: 200px;
        padding-right: 10px;
        &.second {
          padding-top: 28px;
        }
        h6 {
          margin-bottom: 10px;
        }
        a {
          color: $gray-opacity-04;
          transition: $transition;
          line-height: 1.43;
          &:hover {
            color: $green;
          }
        }
      }
      .footer__desc {
        width: 80%;
        max-width: 760px;
        h6 {
          margin-bottom: 10px;
        }
        p {
          margin: 0;
          line-height: 1.43;
        }
      }
    }
  }

  .logout {
    display: flex;
    align-items: center;
    cursor: pointer;

    &__icon {
      width: 15px;
      height: 15px;
    }

    &__text {
      margin-left: 15px;
      font-size: 14px;
      opacity: 0.4;
      color: $gray-opacity-1;
    }
  }
}
