.friends-grid {
  margin-left: -51px;
}

.promo-sports {
  &__title {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    text-align: center;
    color: #b2b2b2;
  }
  &__subtitle {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    text-align: center;
    color: #b2b2b2;

    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    /* or 137% */

    text-align: center;

    mix-blend-mode: normal;
    opacity: 0.8;
  }

  &__header {
    text-align: center;
    margin: 0px 0 36px 0;
    .desc {
      width: 750px;
      max-width: 100%;
      margin: 20px auto 0;
    }
  }
  &__content {
    width: 1170px;
    max-width: 100%;
    margin: 0 auto;
    .promo-sports__lists {
      &-list {
        margin-bottom: 50px;
        .list__header {
          display: flex;
          align-items: flex-end;
          justify-content: space-between;
          .button {
            margin: 0;
          }
        }
        .sources {
          margin-top: 30px;
          display: flex;
          flex-wrap: wrap;
          max-height: 215px;
          overflow-y: auto;

          &::-webkit-scrollbar {
            width: $scroll-width;
            scrollbar-width: thin;
          }

          &::-webkit-scrollbar-thumb {
            background-color: rgba(235, 235, 235, 0.1);
            outline: 1px solid rgba(235, 235, 235, 0.1);
          }

          .sport-item {
            // flex-basis: 49%;
            // margin: 0 3% 10px 0;
            margin: 5px;
            height: 97px !important;
            width: 97px !important;
            max-width: 97px !important;
            background: rgba(235, 235, 235, 0.1);
            border-radius: 8px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            display: flex;
            cursor: pointer;
            &.active {
              .status-icon {
                background: url('../../../assets/images/ico/lists/seen-green.svg')
                  center center no-repeat;
              }
              background-color: rgba(117, 185, 82, 0.2);
            }
            .status-icon {
              background: url('../../../assets/images/ico/plus.svg') center
                center no-repeat;
            }
          }
        }
      }
    }
  }
}
