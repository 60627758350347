.add-custom-wrapper {
  position: relative;
  .custom-actions {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 0;
    z-index: $overlay-z-index - 1;
    width: 260px;
    padding: 15px;
    border-radius: 16px;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    box-shadow: 0 20px 20px 0 rgba(0, 0, 0, 0.2);
    background-color: rgba(65, 65, 68, 0.9);
    transition: opacity 0.3s ease;
    &.left {
      left: -260px;
    }
    &.right {
      right: -260px;
    }
    &.opened {
      visibility: visible;
      opacity: 1;
    }
    &.bottom {
      top: auto;
      bottom: 0;
    }
    &__header {
      margin-bottom: 7px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .close {
        width: 18px;
        height: 18px;
        background: url('../images/ico/close.svg') 0 0 no-repeat;
        opacity: 0.5;
        cursor: pointer;
      }
    }
    .custom-list {
      margin-bottom: 10px;
      &__item {
        position: relative;
        color: $white-opacity-05;
        width: 100%;
        border-radius: 4px;
        padding: 6px 30px 6px;
        cursor: pointer;
        margin-bottom: 2px;
        transition: $transition;
        text-align: left;
        &:last-child {
          margin-bottom: 0;
        }
        &:hover {
          background-color: rgba(235, 235, 235, 0.1);
        }
        &:before,
        &:after {
          content: '';
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: 18px;
          height: 18px;
        }
        &:after {
          right: 6px;
        }
        &:before {
          left: 6px;
          background: url('../images/ico/lists/custom-green.svg') center center
            no-repeat;
        }
        &.marked {
          -webkit-backdrop-filter: blur(6.7px);
          backdrop-filter: blur(6.7px);
          background-color: rgba(235, 235, 235, 0.1);
          color: $white;
          &:after {
            background: url('../images/ico/lists/seen-green.svg') center center
              no-repeat;
          }
        }
      }
    }
    .field {
      margin-bottom: 10px;
      .new-custom__filed--wrapper {
        width: 100%;
        border-bottom: 2px solid rgba(216, 216, 216, 0.1);

        &.Mui-error {
          border-color: red;
        }

        .new-custom__field {
          text-align: center;
          width: 100%;
          color: $white-opacity-04;
          font-family: $font-Bold;
        }
        .custom-icon-clear {
          cursor: pointer;
        }
      }
    }
    .button {
      width: 100% !important;
      margin: 0 !important;
      justify-content: center !important;
      img {
        margin-right: 11px;
      }
    }
  }
}

.error_label {
  color: red;
  font-size: 11px;
}
