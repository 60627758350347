.filters-wrapper {
  position: relative;
  z-index: 1;
  .filters {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .filters__list {
      display: flex;
      align-items: center;
      @import 'filter.item';
    }
  }
  .actions {
    position: relative;
    .navigation {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .reset {
    position: relative;
    max-height: 40px;
    padding: 8px 24px 8px 47px;
    border-radius: 20px;
    border: 2px solid $darker-grey;
    cursor: pointer;
    .icon {
      position: absolute;
      left: 20px;
      top: 52%;
      transform: translateY(-50%);
      width: 12px;
      height: 12px;
      background: url('../../images/ico/close.svg') 0 0 no-repeat;
      background-size: contain;
      opacity: 0.6;
    }
  }
  .line {
    width: 100%;
    height: 1px;
    background-color: rgba(216, 216, 216, 0.1);
    margin: 20px 0;
  }
}
