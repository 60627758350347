&.sport {
  text-align: initial;
  &.empty {
    width: 320px;
    @media all and (max-width: 720px) {
      width: 140px;
    }
  }
  .inner {
    width: 360px;
    .image {
      height: 200px !important;
    }
    .overview {
      margin-bottom: 4px;
    }
  }
}
