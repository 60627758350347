/* simple - enter transition 500ms, exit 200ms */
.fade-appear,
.fade-enter {
  visibility: hidden;
  opacity: 0;
  z-index: 1;
}
.fade-appear-active,
.fade-enter.fade-enter-active {
  visibility: visible;
  opacity: 1;
  transition: opacity 300ms linear 150ms;
}

.fade-exit {
  visibility: visible;
  opacity: 1;
}

.fade-exit.fade-exit-active {
  visibility: hidden;
  opacity: 0;
  transition: opacity 150ms linear;
}
