&.promo-single {
  .inner {
    position: relative;
    width: 1060px;
    height: 380px;
    display: flex;
    align-items: flex-end;
    .image-wrapper {
      width: auto;
      height: 380px !important;
      position: absolute;
      z-index: -1;
      display: flex;
      align-items: flex-start;
      flex-direction: row-reverse;
      background-color: #142630;
      .gradient {
        display: block;
        position: absolute;
        background-image: linear-gradient(
          to right,
          #142630 0%,
          rgba(20, 38, 48, 0) 73%
        );
        height: 100%;
        width: 30%;
        z-index: 100;
        top: 0;
        left: 17%;
      }
      img {
        height: auto;
        width: 83%;
      }
    }
    .content {
      display: flex;
      flex-direction: column;
      flex: 1 1 0;
      overflow: hidden;
      padding: 56px;
      .about {
        width: 50%;
        text-align: left;
        .title {
          color: $white;
          margin: 10px 0;
        }
        .description {
          color: $gray-opacity-08;
          white-space: normal;
          text-align: left;
          font-size: 16px;
          margin-bottom: 30px;
        }
      }
      z-index: 10;
      .controls {
        display: flex;
        .button {
          justify-content: space-between;
          display: flex;
          flex-direction: row;
          &:first-child {
            margin-left: 0;
          }
          .label {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-left: -8px;
            .icon {
              position: relative;
              top: 0;
              left: 0;
              transform: inherit;
              color: $white-opacity-06;
              margin-left: -6px;
            }
          }
          .info {
            display: flex;
            flex-direction: row;
          }
        }
        .text {
          color: $white;
          font-size: 16px;
          font-family: $font-Regular;
          letter-spacing: normal;
          align-self: flex-start;
          margin: 0;
          line-height: 50px;
          &.small {
            color: $white-opacity-03;
            align-self: flex-end;
          }
          &.price {
            align-self: flex-end;
            margin-left: 7px;
          }
        }
      }
    }
  }
}
