.login {
  display: flex;
  justify-content: center;
  .register {
    padding-left: 54px;
    margin-top: 70px;
    width: 516px;
    height: 457px;
    background: rgba(65, 65, 68, 0.9);
    box-shadow: -1px 9px 10px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(54.3656px);
    border-radius: 22px 0 0 22px;

    .items {
      text-align: left;
      padding-top: 25px;

      .item {
        display: flex;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 24px;

        color: #b2b2b2;

        .image {
          padding: 0 15px 15px 0;
        }
      }
    }
  }
}

.login-overlay {
  width: 360px;
  background-color: rgba(65, 65, 68, 0.9);
  min-height: 548px;
  box-shadow: -1px 9px 10px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(54.3656px);
  border-radius: 20px;
}

.restore-password-overlay {
  width: 360px;
  min-height: 387px;
  background-color: rgba(65, 65, 68, 0.9);
  box-shadow: -1px 9px 10px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(54.3656px);

  border-radius: 20px;
}

.tabs {
  width: 100%;
  .MuiTab-root {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: rgba(178, 178, 178, 1);
    text-transform: unset;
  }
  .MuiTabs-flexContainer {
    justify-content: space-between;
  }
}

.text-register {
  position: relative;
  .border {
    border-bottom: 1px solid grey;
  }
  .text-after {
    position: absolute;

    bottom: -8px;
    left: 100px;
    background-color: rgba(65, 65, 68, 0.9);
    padding: 0 17px 0 17px;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;
    text-align: center;

    color: #b2b2b2;
  }
}

.buttons {
  padding: 36px 0 36px 0;
}

.header {
  padding-top: 54px;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  color: #75b952;
}

.form-wrapper {
  padding: 40px;
  @media only screen and (max-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    padding: 36px;
  }
  @media only screen and (max-width: 480px) and (max-height: 800px) and (-webkit-min-device-pixel-ratio: 2) {
    padding: 10px 36px;
  }
  @media only screen and (max-width: 480px) and (min-height: 801px) and (max-height: 900px) and (-webkit-min-device-pixel-ratio: 2) {
    padding: 30px;
  }

  .search-input-wrapper {
    margin-bottom: 10px;
  }
}
