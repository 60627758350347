&-filter--wrapper {
  position: relative;
  margin-right: 20px;
  &:last-child {
    margin-right: 0;
  }
  .filters__list-filter {
    display: flex;
    align-items: center;
    position: relative;
    max-height: 40px;
    padding: 10px 45px 10px 20px;
    border-radius: 20px;
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
    background-color: $darker-grey;
    cursor: pointer;
    transition: $transition;
    &.active {
      background-color: $white-opacity-04;
      h5 {
        transition: $transition;
        color: $white !important;
      }
      .arrow {
        transform: rotateX(-180deg) translateY(-50%);
        top: 33%;
      }
    }
    .filter-type {
      margin-right: 10px;
      text-transform: capitalize;
    }
    &.Sort {
      // margin-left: 20px;
    }
    .arrow {
      position: absolute;
      right: 20px;
      top: 53%;
      transform: rotateX(0) translateY(-50%);
      transition: $transition;
      width: 13px;
      height: 8px;
      background: url('../../images/ico/arrow-down.svg') 0 0 no-repeat;
    }
    .icon {
      margin-right: 10px;
    }
  }
  @import 'filter.dropdown';
}
