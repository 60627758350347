.activity {
  display: flex;
  margin: 11px 0 30px;
  &__lists {
    width: 70%;
    border-right: 1px solid rgba(216, 216, 216, 0.1);
    .grid-navigation {
      margin: 0 0 10px 0;
      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 34px;
        height: 34px;
        border-radius: 50%;
        -webkit-backdrop-filter: blur(9px);
        backdrop-filter: blur(9px);
        background-color: rgba(255, 255, 255, 0.12);
        margin-right: 20px;
        &::after {
          display: none;
        }
      }
    }
    .grid-carousel {
      width: 100% !important;
      & > div {
        margin-left: 0 !important;
      }
    }
  }
  &__followers {
    width: 30%;
    &-list {
      margin: 30px 0 0;
      .follower {
        display: flex;
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0;
        }
        .info {
          flex-basis: 100%;
          margin-left: 10px;
          &__title {
            display: flex;
            align-items: baseline;
            justify-content: space-between;
            margin-bottom: 4px;
          }
          &__action {
            display: flex;
            align-items: center;
            .icon {
              width: 12px;
              height: 12px;
              margin-right: 5px;
            }
          }
        }
      }
    }
  }
}
