h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-Bold, sans-serif;
  margin: 0;
  overflow: hidden;
  &.ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.regular {
  font-family: $font-Regular, sans-serif !important;
}
.bold {
  font-family: $font-Bold, sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  &.green {
    color: $light-green;
  }
}

h1 {
  font-size: 40px !important;
}
h2 {
  font-size: 36px !important;
}
h3 {
  font-size: 26px !important;
}
h4 {
  font-size: 22px !important;
}
h5 {
  font-size: 16px !important;
}
h6 {
  font-size: 14px !important;
}

.fz-40 {
  font-size: 40px !important;
}
.fz-36 {
  font-size: 36px !important;
}
.fz-26 {
  font-size: 26px !important;
}
.fz-24 {
  font-size: 24px !important;
}
.fz-22 {
  font-size: 22px !important;
}
.fz-20 {
  font-size: 20px !important;
}
.fz-16 {
  font-size: 16px !important;
}
.fz-14 {
  font-size: 14px !important;
}
.fz-13 {
  font-size: 13px !important;
}
.fz-12 {
  font-size: 12px !important;
  line-height: 1;
}
.fz-10 {
  font-size: 10px !important;
}

.lh-22 {
  line-height: 20px !important;
}
