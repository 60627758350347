.sports {
  padding-top: 30px;
  .breadcrumbs {
    margin-bottom: 15px;
  }
  .title-page {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    .icon {
      width: 26px;
      height: 26px;
      margin-right: 15px;
      flex-shrink: 0;
      img {
        width: 100%;
      }
    }
  }
}
@import 'details';
