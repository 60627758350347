.person-details {
  .breadcrumbs {
    margin-bottom: 15px;
  }
  .person-header {
    display: flex;
    margin-bottom: 70px;
    .MuiAvatar-root {
      margin-right: 40px;
      width: 100px;
      height: 100px;
    }
    .summary {
      width: 960px;
      max-width: 100%;
      p {
        font-size: 16px;
        color: $gray-opacity-08;
        line-height: 1.38;
      }
    }
    .button {
      margin: 0;
      color: $gray-opacity-08;
      font-size: 16px;
    }
  }
}
