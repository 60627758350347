&.person {
  .inner {
    .image {
      width: 100px !important;
      height: 100px !important;
      border-radius: 50% !important;
      margin: 0 auto 10px;
      overflow: hidden;
      &.empty {
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          position: absolute;
          width: 60px;
          height: 70px;
        }
      }
    }
  }
}
&.user {
  .inner {
    .image {
      width: 100px !important;
      height: 100px !important;
      border-radius: 50% !important;
      margin: 0 auto 10px;
      overflow: hidden;
      &.empty {
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          position: absolute;
          width: 60px;
          height: 70px;
        }
      }
    }
  }
}
