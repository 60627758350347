.sources-wrapper {
  max-width: 770px;
  .title {
    font-size: 18px !important;
    color: $white-opacity-07;
    margin-bottom: 15px;
  }
  .sources {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    @import 'source.item';
    .source-image {
      margin: 0;
    }
  }
}
