.source-item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 400px;
  min-width: 275px;
  width: 100%;
  height: 40px;
  border-radius: 4px;
  background-color: $white-opacity-01;
  margin: 5px 0;
  flex-basis: calc(50% - 5px);
  cursor: pointer;
  &:hover {
    background-color: rgba(0, 0, 0, 0.12);
  }
  &.active {
    background-color: $green-opacity-015;
    .source-name {
      color: $green_2;
    }
  }
  .identifier {
    display: flex;
    align-items: center;
    padding: 8px;
    .source-name {
      margin-left: 13px;
    }
    span {
      color: $white-opacity-08;
    }
    .status-icon {
      position: absolute;
      right: 11px;
      top: 50%;
      width: 18px;
      height: 18px;
    }
  }
  .subscribtion {
    color: $gray2-opacity-05;
    margin-right: 15px;
  }
}
