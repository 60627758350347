.header {
  display: flex;
  align-items: center;
  padding-top: 26px !important;
  padding-bottom: 13px !important;
  flex: 1;
  position: relative;
  z-index: 2;
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    display: none;
  }
  .header-left-side {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
      display: none;
    }
    .header-nav {
      display: flex;
      flex-direction: row;
      list-style-type: none;
      margin: 0 0 0 48px;
      .nav-link {
        position: relative;
        padding: 6px 15px 7px;
        text-decoration: none;
        color: $white-opacity-04;
        transition: $transition;
        font-family: $font-Bold;
        &:hover {
          color: $light-green;
        }
        &.active {
          color: $light-green;
          &:after {
            content: '';
            height: 120%;
            width: 100%;
            position: absolute;
            top: -50%;
            left: -50%;
            transform: translate(50%, 33%);
            opacity: 0.15;
            z-index: -1;
            border-radius: 22px;
            background-color: #75b952;
          }
        }
      }
    }
  }
  .header-right-side {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
    @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
      display: none;
    }
    .avatar {
      width: 40px;
      height: 40px;
      cursor: pointer;
    }
    .button {
      margin-right: 0;
    }
  }
}
