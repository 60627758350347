&.movie {
  .title {
    opacity: 0;
    visibility: hidden;
  }
  &:hover {
    .title {
      opacity: 1;
      visibility: visible;
    }
  }
}
&.sport {
  .item-details {
    opacity: 0;
    visibility: hidden;
  }
  &:hover {
    .item-details {
      opacity: 1;
      visibility: visible;
    }
  }
}
