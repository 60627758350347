&.promo-oscar {
  .inner {
    position: relative;
    width: 1060px;
    height: 380px;
    display: flex;
    align-items: flex-end;
    .image-wrapper {
      width: auto;
      height: 380px !important;
      position: absolute;
      z-index: -1;
      display: flex;
      align-items: flex-start;
      flex-direction: row-reverse;
      background-color: #142630;
      .image {
        height: 380px !important;
      }
    }
    .content {
      width: 100%;
      padding: 57px 40px 40px;
      .icon {
        margin-bottom: 22px;
      }
      .title {
        color: rgba(255, 235, 175, 0.81);
        margin-bottom: 38px;
        letter-spacing: 3.89px;
      }
      .oscars-list {
        display: flex;
        &__item {
          position: relative;
          margin-right: 10px;
          &:last-child {
            margin-right: 0;
          }
          &.winner {
            &:before {
              position: absolute;
              width: 100px;
              height: 150px;
              left: -4px;
              top: -4px;
              content: '';
              background: url('../../images/promo/ico/bitmap.png') center bottom
                no-repeat;
              background-size: 30%;
              border: 4px solid rgba(182, 161, 97, 0.91);
              border-radius: 12px;
              z-index: 1;
            }
            &:after {
              font-family: $font-Bold;
              font-size: 10px;
              letter-spacing: 1.2px;
              color: rgba(182, 161, 97, 0.91);
              position: absolute;
              content: 'WINNER';
              left: 50%;
              bottom: -25px;
              transform: translateX(-50%);
            }
          }
          .image {
            position: relative;
            width: 100px;
            height: 150px !important;
            box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.3);
            border-radius: 8px;
            overflow: hidden;
            img {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
