.parallaxed-banner {
  height: 460px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  .bg-image {
    position: absolute;
    top: -140px;
    pointer-events: none;
    transform: scale(1.15) translateY(75px);
    max-width: 1920px;
    opacity: 0;
  }
  .slide-up-bg-enter {
    transform: scale(1.15) translateY(75px);
    opacity: 0;
    &-active {
      transform: scale(1) translateY(0px);
      transition: all 2400ms ease;
      opacity: 1;
    }
    &-done {
      transform: scale(1) translateY(0px);
      opacity: 1;
    }
  }
  .icons-block {
    position: relative;
    max-width: 270px;
    max-height: 230px;
    width: 100%;
    height: 100%;
    &.left {
      span {
        position: absolute;
        opacity: 0;
        &.bbc {
          top: 10px !important;
          left: 33px !important;
          width: 70px;
          height: 35px;
          z-index: 1;
          &.slide-left-enter {
            transform: translate(272px, 47px) !important;
          }
        }
        &.sky-sport {
          top: 47px !important;
          width: 70px;
          height: 35px;
          left: 68px !important;
          &.slide-left-enter {
            transform: translate(248px, 34px) !important;
          }
        }
        &.channel-4 {
          top: 70px !important;
          left: 165px !important;
          width: 60px;
          height: 32px;
          &.slide-left-enter {
            transform: translate(248px, 34px) !important;
          }
        }
        &.now {
          top: 60px !important;
          left: -45px !important;
          width: 59px;
          height: 29px;
          &.slide-left-enter {
            transform: translate(322px, 43px) !important;
          }
        }
        &.amazon-prime-video {
          top: 100px !important;
          left: 94px !important;
          width: 68px;
          height: 34px;
          &.slide-left-enter {
            transform: translate(221px, 17px) !important;
          }
        }
        &.apple {
          top: 132px !important;
          left: -10px !important;
          width: 48px;
          height: 24px;
          &.slide-left-enter {
            transform: translate(291px, -3px) !important;
          }
        }
        &.hbo-max {
          top: 150px !important;
          left: 26px !important;
          width: 68px;
          height: 34px;
          &.slide-left-enter {
            transform: translate(270px, -9px) !important;
          }
        }
        &.tnt-sports {
          top: 170px !important;
          left: 115px !important;
          width: 58px;
          height: 29px;
          &.slide-left-enter {
            transform: translate(213px, -33px) !important;
          }
        }
        &.netflix {
          top: 126px !important;
          left: 228px !important;
          width: 58px;
          height: 29px;
          &.slide-left-enter {
            transform: translate(213px, -33px) !important;
          }
        }
        &.disney-plus {
          top: 197px !important;
          left: 160px !important;
          width: 58px;
          height: 29px;
          &.slide-left-enter {
            transform: translate(213px, -33px) !important;
          }
        }
        &:nth-child(n) {
          &.slide-left-enter {
            opacity: 0;
            &-active {
              opacity: 1;
              transition: all 2s ease !important;
              transform: translate(0px, 0px) !important;
            }
            &-done {
              opacity: 1;
            }
          }
        }
        img {
          width: 100%;
          box-shadow: 0 12px 20px 0 rgba(0, 0, 0, 0.2);
        }
      }
    }
    &.right {
      span {
        position: absolute !important;
        opacity: 0;
        left: auto !important;
        &.bbc {
          top: 10px !important;
          right: 33px !important;
          width: 70px;
          height: 35px;
          z-index: 1;
          &.slide-right-enter {
            transform: translate(-272px, 47px) !important;
          }
        }
        &.tnt-sports {
          top: 47px !important;
          width: 58px;
          height: 29px;
          right: 68px !important;
          &.slide-right-enter {
            transform: translate(-248px, 34px) !important;
          }
        }
        &.disney-plus {
          top: 60px !important;
          right: -45px !important;
          width: 70px;
          height: 35px;
          &.slide-right-enter {
            transform: translate(-322px, 43px) !important;
          }
        }
        &.apple {
          top: 100px !important;
          right: 94px !important;
          width: 59px;
          height: 29px;
          &.slide-right-enter {
            transform: translate(-221px, 17px) !important;
          }
        }
        &.channel-4 {
          top: 132px !important;
          right: -10px !important;
          width: 48px;
          height: 24px;
          &.slide-right-enter {
            transform: translate(-291px, -3px) !important;
          }
        }
        &.sky-sport {
          top: 150px !important;
          right: 26px !important;
          width: 68px;
          height: 34px;
          &.slide-right-enter {
            transform: translate(-270px, -9px) !important;
          }
        }
        &.now {
          top: 197px !important;
          right: 70px !important;
          width: 58px;
          height: 29px;
          &.slide-right-enter {
            transform: translate(-213px, -33px) !important;
          }
        }
        &.hbo-max {
          top: 160px !important;
          right: 160px !important;
          width: 58px;
          height: 29px;
          &.slide-right-enter {
            transform: translate(-213px, -33px) !important;
          }
        }
        &.netflix {
          top: 66px !important;
          right: 160px !important;
          width: 68px;
          height: 34px;
          &.slide-right-enter {
            transform: translate(-213px, -33px) !important;
          }
        }
        &.amazon-prime-video {
          top: 197px !important;
          right: 160px !important;
          width: 58px;
          height: 29px;
          &.slide-right-enter {
            transform: translate(-213px, -33px) !important;
          }
        }
        &:nth-child(n) {
          &.slide-right-enter {
            opacity: 0;
            &-active {
              opacity: 1;
              transition: all 2s ease !important;
              transform: translate(0px, 0px) !important;
            }
            &-done {
              opacity: 1;
            }
          }
        }
        img {
          width: 100%;
          border-radius: 4px;
          box-shadow: 0 12px 20px 0 rgba(0, 0, 0, 0.2);
        }
      }
    }
  }
  .content {
    max-width: 630px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 35px;
    z-index: 1;
    .text {
      &-main {
        margin: 22px;
        white-space: normal;
        text-align: center;
        opacity: 0;
      }
      &-secondary {
        font-size: 16px;
        color: $gray-opacity-08;
        margin: 0px 0px 38px 0px;
        opacity: 0;
        transform: translateY(30px);
        transition: all 900ms ease;
      }
    }
    .slide-up-50-enter {
      transform: translateY(50px);
      &-active {
        opacity: 1;
        transition: all 900ms ease;
        transform: translateY(0px);
      }
      &-done {
        opacity: 1;
      }
    }
    .slide-up-30-enter {
      transform: translateY(30px);
      opacity: 0;
      &-active {
        opacity: 1;
        transition: all 900ms ease;
        transition-delay: 300ms;
        transform: translateY(0px);
      }
      &-done {
        opacity: 1;
        transform: translateY(0px);
      }
    }
    .slide-up-20-enter {
      opacity: 0;
      &-active {
        opacity: 1;
        transition: all 900ms ease;
        transform: translateY(0px);
        transition-delay: 750ms;
      }
      &-done {
        opacity: 1;
        transform: translateY(0px);
      }
    }
    button {
      opacity: 0;
      width: 170px !important;
      transition-delay: 750ms;
      transform: translateY(20px);
    }
  }
}
