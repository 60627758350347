.promogrid-item {
  text-align: center;
  display: inline-block;
  vertical-align: top;
  contain: layout style;
  height: auto !important;
  margin-bottom: 5px;
  cursor: pointer;
  &.selected {
    border-radius: 8px;
    background-color: rgba(117, 185, 82, 0.2);
  }
  // Import card styles tv-show type
  @import 'grid.item.movie';
  @import 'grid.item.show';
  @import 'grid.item.sport';
  @import 'grid.item.sport.category';
  @import 'grid.item.live';
  @import 'grid.item.person';
  @import 'grid.item.season';
  @import 'grid.item.episode';
  @import 'grid.item.promo.single';
  @import 'grid.item.promo.collection';
  @import 'grid.item.promo.collection.second';
  @import 'grid.item.promo.oscar';
  @import 'grid.item.empty';
  @import 'grid.item.default';
  @import 'grid.item.more';
  .item-details {
    position: relative;
  }
  &:not(.link) {
    .image {
      &:after {
        visibility: hidden;
        opacity: 0;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: all 0.15s ease;
      }
    }
  }
  &.marked {
    .image {
      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 28px;
        height: 28px;
        z-index: 1;
        background: url('../../images/ico/mark.svg') -21px -16px no-repeat;
      }
    }
  }
  &:hover {
    z-index: 100;
  }
  &:not(.promo) {
    .image {
      & > img {
        display: block;
        position: relative;
      }
    }
  }
  .inner {
    position: relative;
    width: 94px;
    height: 118px;
    border-radius: 8px;
    padding-top: 7px;

    &:hover {
      .more {
        visibility: visible;
        opacity: 1;
      }
      .image {
        &:after {
          visibility: visible;
          opacity: 1;
        }
        a {
          &:after {
            visibility: visible !important;
            opacity: 1 !important;
          }
        }
      }
    }
    &.actions-bottom {
      .image {
        &:after,
        a:after {
          background: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0),
            rgba(0, 0, 0, 0.9)
          );
        }
      }
    }
    &.actions-right {
      .image {
        &:after,
        a:after {
          background: linear-gradient(
            to right,
            rgba(0, 0, 0, 0),
            rgba(0, 0, 0, 0.9)
          );
        }
      }
    }
    &:hover {
      .fab {
        opacity: 1;
        visibility: visible;
      }
    }
    &.movie {
      height: 148px;
    }
    .image-wrapper {
      position: relative;
      border-radius: 8px;
      .promo {
        position: absolute;
        left: 15px;
        top: 15px;
        z-index: 1;
      }
      .image {
        display: flex;
        align-items: center;
        position: relative;
        background: #4d4d52;
        overflow: hidden;
        border-radius: 8px;
        margin-bottom: 10px;
        @media all and (max-width: 720px) {
          margin-right: 20px;
        }
        & > img {
          height: 100%;
        }
        &.empty {
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 60px;
            height: 60px;
            position: absolute;
          }
        }
        a {
          height: 100%;
          width: 100%;
          &:after {
            visibility: hidden;
            opacity: 0;
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transition: all 0.15s ease;
          }
          & > img {
            width: 100%;
            height: 100%;
            display: block;
          }
        }
        &:hover {
          &:after {
            visibility: visible;
            opacity: 1;
          }
          a {
            &:after {
              visibility: visible;
              opacity: 1;
            }
          }
        }
      }
    }
    .title {
      margin-bottom: 4px;
      color: $white-opacity-08;
      transition: $transition;
    }
    .subtitle {
      font-size: 13px;
      color: $gray-opacity-05;
    }
  }
}
