.share-custom-wrapper {
  position: relative;
  .custom-actions {
    position: absolute;
    top: -150px;
    z-index: $overlay-z-index - 1;
    width: 290px;
    padding: 15px;
    border-radius: 16px;
    backdrop-filter: blur(10px);
    box-shadow: 0 20px 20px 0 rgba(0, 0, 0, 0.2);
    background-color: rgba(65, 65, 68, 0.9);
    transition: opacity 0.3s ease;
    &.left {
      left: -290px;
      @media only screen and (min-width: 320px) and (max-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
        left: -200px;
      }
    }
    &.right {
      right: -290px;
      @media only screen and (min-width: 320px) and (max-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
        right: -200px;
      }
    }
    &.opened {
      visibility: visible;
      opacity: 1;
    }
    &.bottom {
      top: auto;
      bottom: 0;
    }
    &__header {
      margin-bottom: 7px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .close {
        width: 18px;
        height: 18px;
        background: url('../images/ico/close.svg') 0 0 no-repeat;
        opacity: 0.5;
        cursor: pointer;
      }
    }

    .field {
      margin-bottom: 10px;
      .new-custom__filed--wrapper {
        width: 100%;
        border-bottom: 2px solid rgba(216, 216, 216, 0.1);
        .new-custom__field {
          text-align: center;
          width: 100%;
          color: $white-opacity-04;
          font-family: $font-Bold;
        }
        .custom-icon-clear {
          cursor: pointer;
        }
      }
    }
    .button {
      // width: 100% !important;
      margin: 0 !important;
      justify-content: center !important;
      img {
        margin-right: 11px;
      }
    }
  }
  .link {
    position: relative;
    width: 100%;
    height: 40px;
    padding: 0 10px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    backdrop-filter: blur(6.7px);
    background-color: rgba(235, 235, 235, 0.1);
    .copied-status {
      position: absolute;
      bottom: -15px;
      visibility: hidden;
      opacity: 0;
      transition: $transition;
      &.active {
        visibility: visible;
        opacity: 1;
      }
    }
    input {
      color: $white-opacity-08;
      font-size: 14px;
      font-family: $font-Bold;
      background: none;
      border: none;
      width: 100%;
      padding-right: 10px;
      &::selection {
        background: transparent;
      }
      &:focus {
        outline: none;
      }
    }
    .button {
      margin: 0;
      width: auto;
      backdrop-filter: none;
    }
  }
}
