.suggestions {
  position: absolute;
  right: 0;
  top: 50px;
  border-radius: 20px;
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  background-color: rgba(65, 65, 68, 0.9);
  min-width: 100%;
  min-height: 82px;
  width: fit-content;
  max-width: 696px;
  transition: opacity 0.3s ease 0s, max-height 0.3s ease 0s;
  overflow: hidden;
  text-align: center;
  padding: 20px 30px;
  z-index: 100;
  &.is_closed {
    opacity: 0;
    visibility: hidden;
  }
  &.is_opened {
    opacity: 1;
    visibility: visible;
  }
  .MuiList-root {
    padding: 0;
  }
  &-lists {
    display: flex;
    &__list {
      text-align: left;
      max-width: 230px;
      min-width: 230px;
      &.list__titles {
        margin-right: 10px;
      }
      .MuiList-root {
        margin-top: 10px;
      }
    }
  }
  .user-fullname {
    max-width: 165px;
  }
  .suggestion {
    display: flex;
    align-items: center;
    padding: 0;
    transition: all 0.3s;
    text-align: left;
    border-radius: 4px;
    margin: 4px 0;
    &-movie {
      margin-left: 13px;
      margin-right: 40px;
    }
    &:hover {
      background-image: linear-gradient(
        68deg,
        #4f4f52 15%,
        rgba(79, 79, 82, 0) 85%
      );
    }
    .MuiListItemIcon-root {
      min-width: inherit;
    }
    &-person {
      margin-bottom: 12px;
      .MuiAvatar-root {
        margin-right: 10px;
        width: 40px;
        height: 40px;
      }
    }
    &-name {
      padding-left: 2px;
    }
    &-user {
      margin-bottom: 12px;
      width: 40px;
      .MuiAvatar-root {
        margin-right: 10px;
        width: 40px;
        height: 40px;
      }
    }
    .image {
      width: 40px;
      height: 60px;
      margin-right: 10px;
      border-radius: 4px;
      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
      overflow: hidden;

      img {
        width: 100%;
      }
      &.empty {
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 15px;
          height: 20px;
        }
      }
    }

    .image-sport {
      width: 65px;
      height: 37px;
      margin-right: 10px;
      border-radius: 4px;
      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
      overflow: hidden;

      img {
        width: 100%;
      }
      &.empty {
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 15px;
          height: 20px;
        }
      }
    }
  }
  .not_found {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .MuiListItemText-root {
    margin: 0;
  }
  .view-all {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 34px;
    cursor: pointer;
    margin: 10px 0 0 0;
    padding: 0;
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
    background-color: $white-opacity-01 !important;
  }
}
