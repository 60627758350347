.watch-buy-wrapper {
  height: 466px;
  .content {
    display: flex;
    .media {
      margin-right: 50px;
      text-align: center;
      .image {
        width: 160px;
        height: 240px;
        border-radius: 8px;
        box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.3);
        overflow: hidden;
        margin: 0 auto 12px;
        @media screen and (min-width: 1921px) {
          width: 320px;
          height: 480px;
        }
        img {
          width: 100%;
        }
        &.empty {
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 60px;
            height: 60px;
            position: absolute;
          }
        }
      }
      h5 {
        @media screen and (min-width: 1921px) {
          font-size: 24px !important;
        }
      }
    }
    @import 'sources/sources.wrapper';
    .sources-wrapper {
      @media screen and (min-width: 1921px) {
        width: 800px;
        font-size: 24px !important;
        .MuiTabs-root h5 {
          font-size: 24px !important;
        }
        .source-item {
          max-width: 800px !important;
          height: 80px;
          .source-image {
            width: 96px;
            height: 48px;
          }
        }
      }
    }
  }
}
