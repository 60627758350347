&.promo-collection-second {
  @import 'grid.item.promo.collection.both';
  .image-wrapper {
    .image {
      height: 100% !important;
    }
  }
  .content {
    padding: 45px 49px !important;
    justify-content: flex-end !important;
  }
  .button {
    min-width: 80px;
    height: 40px;
    -webkit-backdrop-filter: blur(9px);
    backdrop-filter: blur(9px);
    background-color: rgba(255, 255, 255, 0.12);
    margin: 0;
    padding: 0 17px !important;
  }
}
