.filter-dropdown {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  left: 0;
  top: 47px;
  padding: 20px;
  border-radius: 20px;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  box-shadow: 0 15px 20px 0 rgba(0, 0, 0, 0.25);
  background-color: rgba(65, 65, 68, 0.9);
  transition: $transition;
  min-width: 100%;
  min-height: 50px;
  &.Sort {
    left: auto;
    right: 0;
  }
  &.active {
    opacity: 1;
    visibility: visible;
  }
  .content {
    @import 'filter.list';
  }
  .line {
    margin-top: 0;
    margin-bottom: 15px;
  }
  .actions {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .button {
      margin: 0;
    }
  }
}
