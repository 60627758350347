.overlay {
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: $overlay-bg;
  z-index: 1301;
  visibility: hidden;
  opacity: 0;
  transition: $transition;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  &-close {
    position: absolute !important;
    right: 0;
    top: 8px;
    background-color: $darker-grey !important;
    margin: -2px 4px !important;
  }
  @media only screen and (max-height: 750px) and (-webkit-min-device-pixel-ratio: 2) {
    align-items: end;
  }
  @media screen and (orientation: landscape) and (max-height: 480px) {
    align-items: center;
  }

  &.active {
    visibility: visible;
    opacity: 1;
    @media screen and (orientation: landscape) and (max-height: 480px) {
      height: 700px;
      position: absolute;
    }
  }
  @import 'sources/sources.wrapper';
}
