.source-image {
  display: flex;
  list-style-type: none;
  width: 48px;
  height: 24px;
  background-color: $darker-grey;
  align-items: center;
  justify-content: center;
  border-radius: 2.6px;
  cursor: pointer;
  overflow: hidden;
  .image {
    width: 100%;
    &.small {
      width: 8px;
      height: 8px;
    }
  }
}
