&.empty {
  &.vertical {
    width: 200px;
  }
  &.horizontal {
    width: 400px;
  }
  @media all and (max-width: 720px) {
    width: 140px;
  }
  .image {
    visibility: hidden;
  }
}
