&.show {
  .title {
    opacity: 0;
    visibility: hidden;
  }
  &:hover {
    .title {
      opacity: 1;
      visibility: visible;
    }
  }
  .bg-cards {
    .card {
      position: absolute;
      top: -10px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 0;
      height: 240px;
      background: $gray-opacity-05;
      &.card-1 {
        width: 88%;
        top: -6px;
        border-radius: 7px;
        background: #414144;
      }
      &.card-2 {
        width: 75%;
        top: -11px;
        border-radius: 6px;
        background: rgba(65, 65, 68, 0.5);
      }
      &.card-3 {
        width: 63%;
        top: -15px;
        border-radius: 5px;
        background: rgba(65, 65, 68, 0.3);
      }
    }
  }
  .has_new_episode {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 20px;
    background-image: linear-gradient(
      to right,
      rgba(117, 185, 82, 0.1),
      rgba(117, 185, 82, 0.8) 32%,
      rgba(117, 185, 82, 0.9) 53%,
      rgba(117, 185, 82, 0.8) 75%,
      rgba(117, 185, 82, 0.1)
    );
  }
}
